import React, { useState, useEffect } from 'react';
import Header from './header/header';
import Footer from './footer/footer';
import './home.css';
import openPage from './images/open-tabs.png';
import C1 from './images/c1.png';
import C2 from './images/c2.png';
import card from './images/female_avtar.png';
import card1 from './images/man-avatar-icon.jpg';
import chandru from './images/chandru.jpeg';
import girish from './images/Girish (2).jpeg';
import accessImage from './images/access-image.png';
import adminTab from './images/admin-tab.png';
import corporateTab from './images/corporate-login.png';
import tick from './images/width.png';
import app1 from './images/app1.png';
import app2 from './images/app2.png';
import app3 from './images/app3.png';
import fidelitusLogo from './images/fidelitus.png'
import freshMakersLogo from './images/FreshMakers.png';
import useCaseIcon from './images/useCase.png';
import experienceIcon from './images/Experience-img.png';
import insswmble from './images/inssemble.png';
import ebenus from './images/ebnus.png';
import { NavLink } from 'react-router-dom';


const App = () => {
  const [activeTab, setActiveTab] = useState('London');
  const [currentPage, setCurrentPage] = useState(1);
  const [animating, setAnimating] = useState(false);
  const cardsPerPage = 3;



  const openCity = (cityName) => {
    setActiveTab(cityName);
  };
  const cards = [
    { img: card, name: 'Suhasini', occupation: 'HR', location: 'Fedilitus', description: `Leaders recognize the LMS as a valuable tool for enhancing employee development and improving training efficiency. They emphasize its positive impact on overall engagement and productivity within the organization. The ability to monitor progress and outcomes is also seen as a significant advantage, enabling better decision-making and resource allocation.` },
    { img: card, name: 'Varsha', occupation: 'Faculty', location: 'Fedilitus', description: `Faculty members value the LMS for its ease of course management and the robust analytics tools that help them track employee progress. They appreciate the resource-sharing capabilities and the customization options that allow them to tailor their courses to their teaching style. Additionally, they find the customer support responsive and helpful when issues arise.` },
    { img: card1, name: 'Ashish', occupation: 'Employee', location: 'Fedilitus', description: `Employees appreciate the LMS for its user-friendly interface and flexible learning options. They find the courses engaging and interactive, which keeps them motivated. Many highlight the convenience of accessing the platform anytime, fitting their busy schedules, and value the supportive community that fosters collaboration and discussion among peers.` },
    { img: card1, name: 'Balaji', occupation: 'Manager', location: 'Inssemble', description: `Leadership views the LMS as a strategic asset for fostering a learning-oriented culture. They highlight its role in streamlining onboarding processes and improving employee retention rates through effective training. Moreover, leaders value the insights gained from data analytics, which help in aligning training programs with organizational goals and assessing their impact on performance.` },
    { img: card1, name: 'Mahesh', occupation: 'Faculty', location: 'Inssemble', description: `Faculty members commend the LMS for its comprehensive content management capabilities. They often note that the platform simplifies the process of creating and updating course materials, allowing them to focus more on teaching. They also appreciate the feedback mechanisms that facilitate communication with Employees, helping them tailor their approach based on individual needs.` },
    { img: card, name: 'Preethi', occupation: 'Employee', location: 'Inssemble', description: `Employees find the LMS to be a transformative learning tool that enhances their skill development. Many express satisfaction with the diverse course offerings, which cater to various professional interests. They appreciate the gamification elements that make learning fun and engaging, and they often mention how the platform encourages a culture of continuous improvement within the workplace.` },
    { img: chandru, name: 'Chandru', occupation: 'M D', location: 'Ebenus', description: `Leadership emphasizes the LMS's ability to drive organizational change by aligning training initiatives with strategic goals. They appreciate the scalability of the platform, which supports a growing workforce. Furthermore, leaders recognize the LMS as a cost-effective solution that reduces training overhead while improving the overall effectiveness of employee development programs.` },
    { img: card1, name: 'Shashidar', occupation: 'Faculty', location: 'Ebenus', description: `Faculty members highlight the LMS's integration with other tools, which simplifies the teaching process and enhances collaboration. They find the grading features efficient, allowing for quicker feedback on assignments. Additionally, many faculty members value the community-building tools, which foster interaction and engagement among employees, enhancing the overall learning experience.` },
    { img: girish, name: 'Girish', occupation: 'Employee', location: 'Ebenus', description: `Employees appreciate the LMS for its personalized learning paths, which allow them to focus on areas that are most relevant to their career growth. Many note that the platform's multimedia resources, like videos and quizzes, make learning more dynamic and enjoyable. They also mention the convenience of mobile access, enabling them to learn on the go.` },

  ];
  const [currentFeaturePage, setCurrentFeaturePage] = useState(1);
  const featureCardsPerPage = 3; // Number of feature cards per page
  const [toggleReadMore,setToggleReadMore] = useState(false);
  const [toggleMoreContent,setToggleMoreContent] = useState(false);
  const [toggleMoreInfo,setToggleMoreInfo] = useState(false);
  const featureCards = [
    {
      img: card,
      title: 'Employee Onboarding',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs',

      ],
      link: '/solution1'

    },
    // Add more feature cards as needed
    {
      img: card,
      title: 'Compliance Training',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution2'

    },

    {
      img: card,
      title: 'Sales Enablement',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution3'

    },
    {
      img: card,
      title: 'Talent Development',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution4'

    },
    {
      img: card,
      title: 'Customer Education',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution5'

    },
    {
      img: card,
      title: 'Partner Enablement',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution6'

    }, {
      img: card,
      title: 'Member Training',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution7'

    }
    , {
      img: card,
      title: 'Front-Line Workforce',
      description: [
        '✓ Host self-branded learning materials',
        '✓ Schedule tests multiple times for employees',
        '✓ Evaluate student’s progress and performance',
        '✓ Get statistical reports in easy-to-read graphs'
      ],
      link: '/solution8'

    }
  ];
  const totalFeaturePages = Math.ceil(featureCards.length / featureCardsPerPage);
  // Automatically change feature card page every 3 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentFeaturePage((prevPage) => (prevPage % totalFeaturePages) + 1);
    }, 3000);

    return () => clearInterval(timer);
  }, [totalFeaturePages]);

  const totalPages = Math.ceil(cards.length / cardsPerPage);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPage((prevPage) => (prevPage % totalPages) + 1);
    }, 3000);

    return () => clearInterval(timer);
  }, [totalPages]);

  useEffect(() => {
    setAnimating(true);
    const timer = setTimeout(() => setAnimating(false), 500);
    return () => clearTimeout(timer);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  // Render feature cards based on currentFeaturePage
  const renderFeatureCards = () => {
    const startIndex = (currentFeaturePage - 1) * featureCardsPerPage;
    const endIndex = startIndex + featureCardsPerPage;
    return featureCards.slice(startIndex, endIndex).map((card, index) => (
      <div key={index} className="feature-card">
        <div className="card-body">
          <img src={card.img} alt="Feature Card" />
          <h3><strong>{card.title}</strong></h3>
          <hr />
          <p>
            {card.description.map((item, idx) => (
              <React.Fragment key={idx}>
                {item}
                <br />
              </React.Fragment>
            ))}
          </p>
          <a href={card.link} className="learn-more-btn">Learn More</a>

        </div>
      </div>
    ));
  }; // Handle pagination change for feature cards
  const handleFeaturePageChange = (page) => {
    if (page >= 1 && page <= totalFeaturePages) {
      setCurrentFeaturePage(page);
    }
  };
  const renderCards = () => {
    const start = (currentPage - 1) * cardsPerPage;
    const end = start + cardsPerPage;
    return cards.slice(start, end).map((card, index) => (
      <div key={index} className={`card ${animating ? 'slide' : ''}`}>
        <div className='name-img'>
          <img src={card.img} alt={card.name} />
          <div className='description1'>
            <h3>{card.name}</h3>
            <p>{card.location}</p>
            <p>{card.occupation}</p>

          </div></div><p>{card.description}</p>
      </div>
    ));
  };

  
  function read() {
    setToggleReadMore(prev=> !prev);
    if(toggleReadMore){
      document.getElementById('useCaseHeading').scrollIntoView({behavior:'smooth'})
    }
  }
  function readContent(){
    setToggleMoreContent(prev=>!prev);
    if(toggleMoreContent){
      document.getElementById('BenefitsHeading').scrollIntoView({behavior:'smooth'})
    }
  }
   function readInfo(){
    setToggleMoreInfo(prev=>!prev);
    if(toggleMoreInfo){
      document.getElementById('FeaturesHeading').scrollIntoView({behavior:'smooth'})
    }
  }
 
 const scrollToHeader=()=>{
    document.getElementById('header').scrollIntoView({behavior:'smooth'})
  }

  const scrollToUseCase=()=>{
    read()
  }
  const scrollToBenefits=()=>{
    readContent()
  }
  const scrollToFeatures=()=>{
    readInfo()
  }



  return (
    <>
     <div id='header'>
     <Header></Header>
     </div>
      <main>
        <div className="container3" >
          <div className="c1 wow slideInLeft" data-wow-duration="1s" data-wow-delay="0s">
            <h5 style={{marginLeft:'15px'}}>The premier global learning platform for employees, enterprises, and partners in the corporate world.</h5>
            <p>A successful learning program depends on a solid platform. Without a strong foundation, engaging 
learners, delivering impactful business results, expanding programs, managing content effectively, 
and optimizing workflows and technology becomes challenging. The Prola-LMS learning platform 
provides the necessary framework to support your employees and business in learning, growing, and 
achieving success both now and in the future.<br />
            <NavLink to="/contact"><button onClick={scrollToHeader} style={{width:'200px',padding:'20px',background:'#042a9a',fontSize:'bold'}}>Try Prola LMS Now</button></NavLink>
            </p>
          </div>
          <div className="c2 wow bounceInUp" data-wow-duration="2s" data-wow-delay="0s">
            <img src={openPage} alt="open-tab" />
          </div>
        </div>
        <p id='first-para'>A globally recognized digital learning 
platform, trusted by corporations 
worldwide</p>
        <div className='image-container'>
          <img src={fidelitusLogo} style={{width:'250px', paddingBottom:'30px'}}/>
          <img src ={insswmble}></img>
          <img src={ebenus} ></img>
          {/* <img src={freshMakersLogo} ></img> */}
         
        </div>
        <div className='lists'>
          <h2>How does Prola LMS Work?</h2>
          <ul>
            <li>Employees register for Prola LMS and access their dashboards.</li>
            <li>Participate in virtual training through various learning methods.</li>
            <li>Prepare different types of questions for mock tests at the topic level.</li>
            <li>The module test will be conducted for employees through the platform on chapter level.</li>
            <li>Faculty can schedule tests accessible to candidates based on course level.</li>
            <li>Evaluate results based on easy-to-read statistics.</li>
            {/* <li>Career guides offer info on applying, registering, preparing, and more</li> */}
            <NavLink to="/contact"><button  id="makeAppointmentBtn" onClick={scrollToHeader}>Try Prola LMS Now</button></NavLink>
          </ul>
        </div>

        <div className='main-features'>
          <div className='row'>
            <div id="cardAlignment" className="col" style={{ paddingTop: '30px' }}>
              <div class="feature-container">
                <div class="feature-card">
                  <div class="card-body" style={{ textAlign: 'left' }}>
                    <img src={useCaseIcon} id="useCaseHeading"></img>
                    <h3 style={{ textAlign: 'center' }}><strong>Uses Cases</strong></h3>
                    <hr />
                    <h6 style={{ paddingBottom: '2px' }}><strong>✓ Employee Onboarding:</strong> Automate onboarding with essential training and resources.</h6>
                    {toggleReadMore?(<>
                    <h6 style={{ paddingBottom: '2px' }}><strong>✓ Compliance Training:</strong> Provide mandatory training on anti-harassment, data protection and regulations.</h6>
                    <h6><strong>✓ Soft Skills Development:</strong> Offer courses on communication, leadership, and teamwork.</h6>
                    <h6><strong>✓	Product Knowledge Training:</strong>Equip sales teams with in-depth product knowledge.</h6>
                    <h6><strong>✓ Leadership Development: </strong>Create programs for high-potential employees.</h6>
                    <h6><strong>✓ Customer Service Training:</strong> Train staff to enhance customer experiences.</h6>
                    <h6><strong>✓ Technical Skills Training:</strong> Provide training on relevant software and tools.</h6>
                    <h6><strong>✓ Mandatory Training:</strong> Cover health and safety, fire safety, and first aid.</h6>
                    <h6><strong>✓ Sales Enablement:</strong>  Equip sales teams with the skills to effectively sell.</h6>
                    <h6><strong>✓ Partnership and Channel Training:</strong> Train partners on products and sales strategies.</h6>
                    <h6><strong>✓ Induction Training:</strong>  Offer new employees an overview of the organization.</h6>
                    <h6><strong>✓ Continuous Learning:</strong>  Promote ongoing professional development.</h6>
                    <h6><strong>✓ Performance Support:</strong> Provide just-in-time resources for daily tasks.</h6>
                    <h6><strong>✓ Global Training:</strong>  Deliver training across various locations and languages.</h6>
                    </>
                    ):
                    <span id='dots'>....</span>}
                    <button id='readBtn' className='btn btn-info' type='button' onClick={()=>scrollToUseCase()}>{toggleReadMore? 'Read Less':'Read More'}</button>
                  </div>
                </div>
              </div>
            </div>

            <div id="cardAlignment" className="col" style={{ paddingTop: '30px' }}>
              <div class="feature-container">
                <div class="feature-card">
                  <div class="card-body" style={{ textAlign: 'left' }}>
                    <img src="https://prolaskool.com/c15a54eed5fd4a5949b412bfa27d5ca6.png"  id='BenefitsHeading'></img>
                    <h3 style={{ textAlign: 'center' }}><strong>Benefits for employees</strong></h3>
                    <hr />
                    <h6 style={{ paddingBottom: '2px' }}><strong>✓ Increased Efficiency:</strong> Streamline training processes by automating administrative tasks.</h6>
                    {toggleMoreContent?(<>
                    <h6 style={{ paddingBottom: '2px' }}><strong>✓ Cost Saving:</strong> Achieve up to 50% reduction in training costs through online learning.</h6>
                    <h6><strong>✓ Improved Compliance:</strong> Ensure regulatory adherence by monitoring compliance training.</h6>
                    <h6><strong>✓ Enhanced Engagement:</strong> Boost employee motivation with personalized learning experiences.</h6>
                    <h6><strong>✓ Scalability: </strong> Efficiently train large groups without incurring additional infrastructure costs.</h6>
                    <h6><strong>✓ Data Insights:</strong> Track learner progress and evaluate training effectiveness. </h6>
                    <h6><strong>✓ Flexibility:</strong> Provide on-demand training access anytime, anywhere.</h6>
                    <h6><strong>✓ Standardization:</strong> Maintain consistent training across the organization. </h6>
                    <h6><strong>✓ Knowledge Retention: </strong>  Enhance retention by up to 60% with interactive learning experiences.</h6>
                    <h6><strong>✓ Better ROI: </strong> Assess training impact to ensure a strong return on investment. </h6>
                    </>
                    ):
                    <span id='dots'>....</span>}
                    <button id='readBtn' className='btn btn-info' type='button' onClick={()=>scrollToBenefits()}>{toggleMoreContent? 'Read Less':'Read More'}</button>
                  </div>
                </div>
              </div>
            </div>   

            <div id="cardAlignment" className="col" style={{ paddingTop: '30px' }}>
              <div class="feature-container">
                <div class="feature-card" style={{paddingBottom:'4.5%'}}>
                  <div class="card-body" style={{ textAlign: 'left' }}>
                    <img src={experienceIcon} id='FeaturesHeading'></img>
                    <h3 style={{ textAlign: 'center' }}><strong>Features</strong></h3>
                    <hr />
                    <h6><strong>✓ Content Management </strong>-  Create and upload content.</h6>
                    {toggleMoreInfo?(<>
                      <h6>-  Organize content effectively.</h6>
                    <h6>-  Delivering content efficiently.</h6>
                    <h6>-  Manage all content seamlessly.</h6>
                    <h6><strong>✓  Assessments</strong></h6>
                    <h6>- User-friendly interface for assessments, quizzes, and surveys.</h6>
                    <h6>- Tools for course administration, grading, and assessment. </h6>
                    <h6>- Conduct online assessments and provide immediate feedback.</h6>
                    <h6><strong>✓  Gamification</strong></h6>
                    <h6>-  Automatically generate certifications and badges at the course level. </h6>
                    <h6>-  Implement strategies to engage employees in the learning process.</h6>
                    <h6>-  Enhance the learning experience, boost employee engagement, and achieve desired outcomes.</h6>
                    <h6>- Go beyond simply adding points, badges, or leaderboards.</h6> 
                    <h6><strong>✓ Reports & Dashboards</strong>  </h6>
                    <h6>-  Built-in reporting and dashboards for employees, faculties, Managers / Supervisors, Senior Leadership Stakeholders. </h6>
                    <h6>-  Single platform to track participation, completion, and performance. </h6>
                    <h6>-  Powerful dashboards and reports to manage the learning process. </h6>
                    </>
                    ):
                    <span id='dots'>....</span>}
                    <button id='readBtn' className='btn btn-info' type='button' onClick={()=>scrollToFeatures()}>{toggleMoreInfo? 'Read Less':'Read More'}</button>
                  </div>
                </div>
              </div>
            </div>          






           

            {/* <div id="cardAlignment" className="col" style={{ paddingTop: '30px' }}>
              <div class="feature-container">
                <div class="feature-card">
                  <div class="card-body" style={{ textAlign: 'left' }}>
                    <h3 style={{ textAlign: 'center' }}><strong>Excellent User Experience</strong></h3>
                    <hr />
                    <h6 style={{ paddingBottom: '2px' }}><strong>✓ Handy portal for institutions and employees</strong></h6>
                    <h6><strong>✓ Dynamic, efficient, and affordable platform</strong></h6>
                    <h6><strong>✓ Offer career guidance with the application</strong></h6>
                    <h6><strong>✓ Available on Google Play, Apple App Store, and the Windows Store</strong></h6>
                  </div>
                </div>
              </div>
            </div> */}




           
           
          </div>
        </div>

        {/* <div className="pagination">
          {Array.from({ length: totalFeaturePages }, (_, index) => (
            <button
              key={index}
              className={`pagination-button ${index + 1 === currentFeaturePage ? 'active' : ''}`}
              onClick={() => handleFeaturePageChange(index + 1)}
            />
          ))}
        </div> */}

        <div className='Access-app'>
          <div className='div1' >
            <h2>How to Access Prola LMS App?</h2>
            <ul>
              <li> <img src={tick} alt="tick-app" className='TickImg'/>After onboarding, the organization will provide the necessary details to the admin.</li>
              <li style={{width:'100%'}}> <img src={tick} alt="tick-app" className='TickImg' />The app will verify these details and send the login credentials to the admin's email.</li>
              <li> <img src={tick} alt="tick-app" className='TickImg'/>The admin will distribute the login information to employees and faculty.</li>
              <li> <img src={tick} alt="tick-app" className='TickImg'/>Admins and faculty can log in to the dashboard to upload content.</li>
              <li> <img src={tick} alt="tick-app" className='TickImg'/>Employees and faculty can download the app and begin using it.</li>
            </ul>
          </div>
          <img src={accessImage} alt="access-app" id='access'/>
        </div>
        <div className='tabs-list'>
          <h2>Introduction</h2>
          <p>Prola LMS Application provides comprehensive end-to-end solutions through a three-pronged 
          approach for admins, employees, and faculty.</p>
          <div className="tab">
            <button className={`tablinks ${activeTab === 'London' ? 'active' : ''}`} onClick={() => openCity('London')}>Admin Panel</button>
            <button className={`tablinks ${activeTab === 'Paris' ? 'active' : ''}`} onClick={() => openCity('Paris')}>Employee App</button>
            <button className={`tablinks ${activeTab === 'Tokyo' ? 'active' : ''}`} onClick={() => openCity('Tokyo')}>Faculty App</button>
            {/* <button className={`tablinks ${activeTab === 'last' ? 'active' : ''}`} onClick={() => openCity('last')}>Product App</button> */}

          </div>
          <div id="London" className="tabcontent" style={{ display: activeTab === 'London' ? 'block' : 'none' }}>
            <img src={adminTab} alt="access-app" />
          </div>
          <div id="Paris" className="tabcontent" style={{ display: activeTab === 'Paris' ? 'block' : 'none' }}>
            <img src={corporateTab} alt="corporate-app" />

          </div>
          <div id="Tokyo" className="tabcontent" style={{ display: activeTab === 'Tokyo' ? 'block' : 'none' }}>
            <img src={adminTab} alt="access-app" />

          </div>
          <div id="last" className="tabcontent" style={{ display: activeTab === 'last' ? 'block' : 'none' }}>
            <img src={adminTab} alt="access-app" />

          </div>
        </div>
        <h2>What clients say about us?</h2>
        <div className="pagination-container">

          <div className="cards-pagination">
            {renderCards()} {/* Renders a list of cards */}
          </div>
          <div className="pagination-dots">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`pagination-dot ${index + 1 === currentPage ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              />
            ))}
          </div>
        </div>

      </main>

      <div id="firstDivs">
        <div id="lookDiv">
          <p id="divContent">DOWNLOAD APP</p>
          <h4>Take a Look</h4>
        </div>
        <div class="vl"></div>
        <div id="lookDiv2">
          <div id="widHeig">
            <a href="https://play.google.com/store/apps/details?id=com.prolatech.prolalms&hl=en" target="_blank" rel="noreferrer">
              {/* <img class="store-icon" alt="Get it on Google Play" src="https://prolaskool.com/018d1718a2413065e49e4a5bd4e3eee1.png"> */}
              <img src={app1} alt="access-app" />

            </a>
          </div>
          <div id="widHeig">
            <a href='#' rel="noreferrer">
              <img src={app2} alt="access-app" />
            </a>
          </div>
          <div id="widHeig">
            <a href="https://apps.microsoft.com/detail/9ncbbh0f3x5t?hl=en-us&gl=US" target="_blank" rel="noreferrer">
              <img src={app3} alt="access-app" />
            </a>
          </div>
        </div>
      </div>
     <div>
     <Footer></Footer>
     </div>

    </>
  );
};

export default App;
